<template>
  <div class="new_register_page">
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="loader" />
    
    <!-- CONTENTS -->
    <div class="register-page-container">

      <div class="">

        <!-- FORM SCROLLING -->
        <div class="form_scorlling_register_3">

        <!-- HEADER -->
        <div class="mob_header_cont">

          <!-- HEADER DSEKTOP -->
          <div class="mob_header hide_on_mobile">
            <div class="mob_header_div mob_div_pl display_flex_left">
              <img src="/svg/icon_white_arrow_left.svg" role="button" @click="redirect()"><br>
            </div>
            <div class="mob_header_div mob_div_pr display_flex_right">
              <img src="/apalywhite.svg" class="mob_header_logo"/>
            </div>
          </div>

          <!-- HEADER MOBILE -->
          <div class="mob_header hide_on_desktop">
            <div class="mob_header_div display_flex_left">
              <img src="/svg/icon_white_arrow_left.svg" role="button" @click="redirect()"><br>
            </div>
            <div class="mob_header_div display_flex_right">
              <img src="/apalywhite.svg" class="mob_header_logo"/>
            </div>
          </div>

          <!-- STEPS NO SELECTED PROVIDER-->
          <div class="mob_step_cont" v-if="!selectedProvider">
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">1</div>
              <div class="mob_step_text">Create <br> account</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">2</div>
              <div class="mob_step_text">Select <br> Provider</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">3</div>
              <div class="mob_step_text">Verify your <br> eligibility</div>
            </div>
            <!-- ONly show if user added a fam member -->
            <div class="mob_step_gap" v-if="addFam"></div>
            <div class="mob_step" v-if="addFam">
              <div class="mob_step_num mob_step_done">4</div>
              <div class="mob_step_text"> <div class="member_nowrap">Add Family</div> Member</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_active">{{ addFam ? '5':'4' }}</div>
              <div class="mob_step_text">Activate <br> membership</div>
            </div>
          </div>

          <!-- STEPS WITH SELECTED PROVIDER-->
          <div class="mob_step_cont" v-else>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">1</div>
              <div class="mob_step_text">Select <br> Provider</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">2</div>
              <div class="mob_step_text">Create <br> account</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">3</div>
              <div class="mob_step_text">Verify your <br> eligibility</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_active">4</div>
              <div class="mob_step_text">Schedule your<br> first visit</div>
            </div>
          </div>
        </div>

        <!-- DPC/PROVIDER DETAILS -->
        <!-- {{ memberCards[0].provider }} -->
        <!-- Show only if one card is being displayed -->
        <div 
          class="mob_header_bg mob_adjust_margin" 
          v-if="selectedProvider && memberCards?.length === 1" 
          :id="memberCards?.length === 1 ? 'default_top_scroll':''"
        >
          <div class="mob_header_provider_cont">
            <div class="mob_header_title" >You've selected:</div>
            <div class="provider_container">
              <!-- LOGOS -->
              <div class="provider_logo_container">
                <div class="provider_logo" v-if="memberCards[0]?.provider?.logo" :style="`background-image:url(${memberCards[0]?.provider?.logo})`"></div>
                <div class="provider_logo" v-else :style="`background-image:url('/tpa/clinicsAndOrganizations.svg')`"></div>
              </div>

              <span class="provider_location">
                <span class="font-16 provider_title">{{ memberCards[0].provider.name }}</span> 
                <br /> 
                <span class="font-12 provider_location">
                  {{ memberCards[0]?.provider?.address1 }},
                  {{ memberCards[0]?.provider?.city }},
                  {{ memberCards[0]?.provider?.state }},
                  {{ memberCards[0]?.provider?.zip }}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div v-else style="height: 160px;" :id="memberCards?.length === 1 ? '':'default_top_scroll'"></div>

        <!-- FORM -->
        <div :class="`mem_form mob_register_form ${!selectedProvider ? 'mob_adjust_margin':''}`" >
            <!-- <div class="test">{{ memberInfo }}</div> -->

            <div class="mem_dark_blue mt-3" style='text-align:center;color:red;font-size:25px'>YOUR MEMBERSHIP IS STILL PENDING</div>

            <div class="mem_text mt-3" style='text-align:center;'>
              In order to gain 24x7 access to your provider, you must complete your intake visit. This can be done virtually or in person.  Please click the orange button below to set up your visit.
            </div>

            <div style="color: #102A4C; font-size: 24px; font-weight: 700; text-align: center; padding-top: 20px">Membership cards</div>

            
            
            <div class="mem_card_swipe_hold">
              <!-- Card Swiper -->
              <div class="mem_card_swiper" v-if="memberCards && memberCards.length !== 0 && showSwiper">
                <div class="mem_card_swipeLeft" v-if="card > 0">
                  <img src="/svg/icon_chevron_left_white.svg" class="mem_card_swiper_icon" @click="swipe('left')">
                </div>
                <div class="mem_card_swipeRight" v-if="card < memberCards.length - 1">
                  <img src="/svg/icon_chevron_right_white.svg" class="mem_card_swiper_icon" @click="swipe('right')">
                </div>
              </div>

              <!-- card -->
              <div class="mem_card_swipe">
                <!-- This div will center all cards regardless of count-->
                <div class="member_center_cards" id="member_center_cards">

                  <!-- MEMBERSHIP CARD -->
                  <div class="mem_card_main" v-for="(card, c) in memberCards" :key="`C`+c" :id="`card`+c">
                    <div class="mem_card_holder">
                        <div class="mem_card_title">APC Membership</div>
                        
                        <div class="mem_card_cont">

                          <!-- Status -->
                          <div class="mem_row">
                              <div class="mem_col">
                                  <div class="mem_label">Membership: </div>
                              </div>
                              <div class="mem_col ml-2">
                                  <div class="mem_data member_nowrap" :style="`color:${statusColor(card.status)};`">
                                      <div class="mem_status_dot">&bullet;</div>
                                      {{ statusText(card.status) }}
                                  </div>
                              </div>
                          </div>

                          <!-- Name -->
                          <div class="mem_row">
                              <div class="mem_col">
                                  <div class="mem_label">Name: </div>
                              </div>
                              <div class="mem_col">
                                  <div class="mem_data ml-2">{{ card.name }}</div>
                              </div>
                          </div>

                          <!-- Employer -->
                          <div class="mem_row">
                              <div class="mem_col">
                                  <div class="mem_label">Employer: </div>
                              </div>
                              <div class="mem_col ml-2">
                                  <div class="mem_data">{{ card.employer }}</div>
                              </div>
                          </div>

                          <!-- Provider  -->
                          <div class="mem_row">
                            <div class="mem_col">
                              <div class="mem_label member_nowrap">Advanced Primary Care Plus</div>
                              <div class="mem_employer_cont" v-if="card.provider && card.provider.length !== 0">
                                  <img :src="card.logo ?card.logo:'/tpa/clinicsAndOrganizations.svg'" class="mem_employer_logo"> 
                                  <div class="mem_employer_info">
                                      <div class="mem_employer_name">{{ card.provider.name }}</div>
                                      <div class="mem_employer_address_cont">
                                          <img src="/svg/cardLocation.svg" class="mem_employer_address_icon">
                                          <div v-if="card.provider">
                                              {{ card.provider.address1 }}, {{ card.provider.city }}, {{ card.provider.state }}, {{ card.provider.zip }}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="mem_col" v-else>
                                  <div class="mem_data"> No Advanced Primary Care</div>
                              </div>
                            </div>
                          </div>

                          <!-- OPTIONS -->
                          <div class="mem_row mem_options_container" v-if="card.provider && card.provider.length !== 0">
                            <!-- If selected provider has Custom Portal Link -->
                            <div class="mem_options" v-if="card.providerPortal">
                              <div @click="openCustomLink()" class="header_getStarted_btn mem_btn">
                                Activate membership
                              </div>
                              <!-- <div style="height: 36px"></div> -->
                            </div>

                            <!-- If selected proivder only has Phone Number -->
                            <div class="mem_options" v-else-if="card.provider.phone">
                              <div v-if="card.provider">
                                <div @click="openKeyboard(card.provider.phone)" class="header_getStarted_btn mem_btn">
                                  Call clinic &nbsp;
                                  <img src="/svg/icon_white_telephone.svg" style="width:auto; height: 20px;">
                                </div>
                                <div class="member_nowrap mt-3"> or dial {{ card.provider.phone }} </div>
                              </div>
                            </div>

                            <!-- If nothing -->
                            <div class="mem_options" v-else-if="!card.provider.phone && !card.providerPortal">
                              <div class="mem_row">
                                <div class="mem_col" style="padding-right: 10px;">
                                  <img src="/svg/icon_info-circle.svg" style="width:auto; height: 20px;">
                                </div>
                                <div class="mem_col" style="padding-right: 0px;">
                                  <div class="mem_note">
                                    The clinic will reach out to schedule your first appointment
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Card footer -->
                          <div class="mem_row mem_card_line" style="margin: 0">
                            <div class="mem_card_footer">
                              <span clas="member_nowrap">Powered by</span> <img src="/apaly-logo.png" class="mem_card_foot_logo">
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="mem_text mt-3" style='text-align:center;'>
              If you need assistance, please contact us at 
              <span style="color:#0069F3;">support@apaly.com</span>
            </div>

            <!-- Show only if relationship is Self and card is only 1 -->
            <div :class="`mem_row mt-4`" v-if="memberCards?.length === 1 && memberInfo?.subscriberRelation == 'Self'">
              <!-- <div class="mem_col" style="margin: auto; padding: 0;"> 
                <div class="mem_dark_blue" style="font-weight: bold; text-align: center; width: 100%;">
                  Provide 24/7 care for you family members!
                </div>
                <div @click="openAddNewMember()" class="header_getStarted_btn mem_btn_darkBlue mt-5">
                  Add my family members
                </div>
              </div> -->
            </div>
            <div :class="`mem_row ${memberCards?.length === 1 && memberInfo?.subscriberRelation == 'Self' ? 'mt-5':'mt-10'}`">
              <div class="mem_col" style="margin: auto; padding: 0;"> 
                <div @click="redirect()" class="header_getStarted_btn mem_btn_blue">
                  View my account
                </div>
              </div>
            </div>

            <div class="mem_row mt-10" style="display: none">
              <div class="mem_col" style="display: none">
                <button class="mem_share">
                  <img src="/svg/icon_share.svg" class="mem_share_icon">
                  Share
                </button>
              </div>
              <div class="mem_col">
                <!-- <button class="apple-wallet-button" @click="addToAppleWallet">
                  <img src="https://developer.apple.com/design/human-interface-guidelines/wallet/overview/overview-hero/overview-hero-asset@3x.png" alt="Apple Wallet Logo" width="20" height="20">
                  Add to Apple Wallet
                </button> -->

                <!-- <apple-pay-button buttonstyle="black" type="plain" locale="en-US"></apple-pay-button> -->

                <!-- <apple-wallet-button
                  buttonstyle="black"
                  compact="true"
                  locale="en-US"
                  onclick="window.location.href='https://applepaydemo.apple.com/static/order/123456789.order';"
                /> -->

              </div>
            </div>

            

            <!-- NOTICE -->
            <!-- <div class="mem_warn_cont">
              <div class="mem_warn_title">
                <img src="/svg/icon_warn_red.svg" alt="" class="mem_warn_icon">
                Important Message
              </div>
              <br>
              <div class="mem_warn_text">
                Do not present your insurance card to the provider!
                <br>
                <strong>Only present this membership card.</strong>
              </div>
            </div> -->

            <br>

        </div>

        </div>
        
      </div>

    </div>

  </div>
</template>

<script>
import api from "@/services/api";
import { mapState } from 'vuex';
import FullscreenLoader from '../../../Common/FullscreenLoader.vue';

export default {
  components: {  
      FullscreenLoader
  },
  data() {
      return {
        showSwiper: true,
        screenWidth: window.innerWidth,
        memberCards: [],
        card: 0,
        selectedProvider: null,
        loader: true,
        addFam: false,
      };
  },
  computed: {
      ...mapState({
          memberInfo: state => state.memberInfo,
          registrationData: state => state.registrationData,
      }),
      loggedIn() {
        if(this.$cookies.get("token")) {
          return true
        }
        else {
          return false
        }
      },
  },
  mounted() {
    // Scroll to top
    // window.scrollTo({ top: -100, behavior: "smooth", });
    // window.scrollTo({ top: 0, behavior: "smooth", });
    
    // setTimeout(() => {
    //   // document.getElementById("default_top_scroll").scrollIntoView({
    //   //   behavior: "smooth",
    //   //   top: 0
    //   // });
    //   alert();
    //   const offset = -156; // Offset value in pixels
    //   window.scrollTo({
    //     top: Math.max(window.pageYOffset + offset, 0), // Ensure the scroll position is not negative
    //     behavior: 'smooth'
    //   });
    // }, 2000);
  },
  created() {
    // // IF logged in, redirect to marketpalce
    // if(this.loggedIn) {
    //     this.$router.push("/marketplace");
    // }
    // Get Selected Provider
    this.getHomeInformation();

    // Redirect
    localStorage.setItem('redirect', '/home');

    // Scroll to top
    // window.scrollTo({ top: -156, behavior: "smooth", });
    


    // Load Apple Pay Button script
    // const script = document.createElement("script");
    // script.async = true;
    // script.setAttribute("crossorigin", "anonymous");
    // // script.src = "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
    // script.src = "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
    // document.head.appendChild(script);

    // Add event listener for resize event
    window.addEventListener('resize', this.handleResize);
    
  },
  destroyed() {
    // Remove event listener when component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    memberInfo() {
      this.getMemberCards();
    }
  },
  methods: {
    openAddNewMember() {
      // Emptty the state.familyMemberData, first
      this.$store.commit("setFamilyMemberData", []);

      // Set the redirect to account settings
      localStorage.setItem('redirect', '/account/details');
      
      // Then go here
      this.$router.push("/add-family-member");
    },
    handleResize() {
      // Update screenWidth data property with the new width
      this.screenWidth = window.innerWidth;
    },
    redirect() {
      //Get redirect destination from localStorage
      let redirect = localStorage.getItem('redirect');

      //See where to redirect
      if(redirect == "/home") {
        this.$router.push('/home')
      }
      else if(redirect == "/account/details") {
        this.$router.push('/account/details')
      }
      else {
        console.log("Redirect is empty: ", redirect);
        this.$router.push('/marketplace');
      }
    },
    swipe(dir) {
      if(dir == "right") {
        if(this.card < (this.memberCards.length - 1)) {
          this.card = this.card + 1;
          document.getElementById('card'+this.card).scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "center",
          });
        }
      }
      else {
        if(this.card > 0) {
          this.card = this.card - 1;
          document.getElementById('card'+this.card).scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "center",
          });
        }
      }

      console.log("Centered card: ", this.card);
    },
    getMemberCards() {
      console.log('Member Info:', JSON.parse(JSON.stringify(this.memberInfo)));
      //See if localStorage.cards exists
      if(JSON.parse(localStorage.getItem('cards'))) {
        // Show only the added family member's card

        // Get dependent IDs from localStorage.cards
        const cards = JSON.parse(localStorage.getItem('cards')) || [];

        // Push to this.memberCards
        if(this.memberInfo && this.memberInfo.family) {
          console.log(this.memberInfo.family);

          for (const card of cards) {
            const memID = card.memID; // Assuming card object has a memID property
            const familyMembers = this.memberInfo.family;
            
            // Find the family member with matching ID
            const familyMember = familyMembers.find(member => member.id === memID);

            // If a matching family member is found, push it to the foundFamilyMembers array
            if (familyMember) {
              // this.memberCards.push(familyMember);
              this.memberCards.push(
                {
                  status: familyMember.subscription ? familyMember.subscription.status : null,
                  name: familyMember.firstName +" "+familyMember.lastName,
                  employer: familyMember.employer ?familyMember.employer.companyName : null,
                  provider: familyMember.location ? familyMember.location : [],
                  providerPortal: familyMember.providerPortal ? familyMember.providerPortal : null,
                  logo: familyMember.location.logo ? familyMember.location.logo : null
                }
              );
            }
          }
        }
      }
      else {
        this.memberCards.push(
          {
            status: this.memberInfo.subscription ? this.memberInfo.subscription.status : null,
            name: this.memberInfo.firstName +" "+this.memberInfo.lastName,
            employer: this.memberInfo.companyName ? this.memberInfo.companyName : null,
            provider: this.memberInfo.location ? this.memberInfo.location : [],
            providerPortal: this.memberInfo.providerPortal ? this.memberInfo.providerPortal : null,
            logo: this.memberInfo.location.logo ? this.memberInfo.location.logo : null
          }
        );
        if(this.memberInfo && this.memberInfo.family && this.memberInfo.subscriberRelation === 'Self') { // If subscriber has family
          // Get length 
          let length = this.memberInfo.family.length;
          let family = this.memberInfo.family;
          console.log("Family length: ", length);
          for(var x=0; x < length; x++) {
            this.memberCards.push(
              {
                status: family[x].subscription ? family[x].subscription.status : null, 
                name: family[x].firstName+" "+family[x].lastName,
                employer: family[x].employer ? family[x].employer.companyName : null,
                provider: family[x].location ? family[x].location : [],
                providerPortal: family[x].providerPortal ? family[x].providerPortal : null,
                logo: family[x].location ? family[x].location.logo : null // Forgot to add in Family memberInfo endpoint. Must tell BE
              }
            );
          }
        }
      }
      

      console.log("Member Card: ", this.memberCards);

      // See if cards are below 3
      if(this.memberCards && this.memberCards.length <= 3) {
        this.showSwiper = false;
      }
      else if(this.memberCards && this.memberCards.length > 1 && this.screenWidth <= 1020) {
        // Mobile screens, show swipers if cards are more than 1
        this.showSwiper = true;
        // if(this.screenWidth >= 1020) {
        // }
      }
      else {
        this.showSwiper = true;
      }

      setTimeout(() => {
        // Align first card in the center by default
        if(this.memberCards && this.memberCards.length <= 3 && this.screenWidth >= 1020) {
          // Desktop screen | cards are 3 or below
          var cardCenter = document.getElementById('member_center_cards');
        }
        else if(this.memberCards && this.memberCards.length >= 4 && this.screenWidth >= 1020) {
          // Desktop screen | cards are 4 and up
          if(document.getElementById('card1')) {
            // Second card gets centered
            var cardCenter = document.getElementById('card1');
            this.card = 1;
          }
        }
        else if(this.memberCards && this.memberCards.length !== 0 && this.screenWidth <= 1020) {
          // Mobile screen | first card gets centered
          var cardCenter = document.getElementById('card0');
          this.card = 0;
        }
        
        console.log("Centered card: ", this.card);
        // Check if the element exists
        if (cardCenter) {
          cardCenter.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
        }
      }, 200);

      // Get the addFam from localStorage to see if user added a family or not
      if(localStorage.getItem("addFam") == "yes" || this.memberCards.length > 1) {
        this.addFam = true;
      }
      else {
        this.addFam = false;
      }

    },
    // addToWallet() {
    //     // Define pass data (replace with your own pass data)
    //     var passData = {
    //         "passTypeIdentifier": "pass.net.apaly.member.apalyTestPass", // Unique to apaly
    //         "serialNumber": "123456",
    //         "teamIdentifier": "ABCDEFG123",
    //         "authenticationToken": "your_authentication_token",
    //         "webServiceURL": "https://example.com/passes/"
    //     };

    //     // Convert pass data to JSON string
    //     var passJson = JSON.stringify(passData);

    //     // Encode pass JSON to base64
    //     var passBase64 = btoa(passJson);

    //     // Generate Apple Wallet URL
    //     var appleWalletUrl = "wallet://add-pass/?pass=" + encodeURIComponent(passBase64);

    //     // Redirect user to Apple Wallet
    //     window.location.href = appleWalletUrl;
    // },
    openCustomLink() {
      if(this.memberInfo && this.memberInfo.providerPortal) {
        window.open(this.memberInfo.providerPortal, '_blank');
      }
      else {
        alert("No custom link was found");
      }
    },
    openKeyboard(phone) {
      if(phone) {
        window.location.href = 'tel:' + phone;
      }
      else {
        alert("No phone number was found");
      }
    },
    statusColor(status) {
      if(status == "pending") return "#FFBD44" // Yellow
      else if(status == "active") return "#04D900" // Green 
      else if(status == "inactive") return "#D90101" // Red 
      else return "#CCCCCC" // Gray
    },
    statusText(status) {
      if(status == "pending") return "Pending"
      else if(status == "active") return "Active" 
      else if(status == "inactive") return "Inactive" 
      else return "Error"
    },
    getHomeInformation() {
      console.log("getHomeInformation");
      api()
      .get(`/member/app/home`)
      .then((res) => {
      console.log("/member/app/home: ", res.data);
      if (res) {

        this.providerId = res.data.providerId;
        this.locationId = res.data.locationId;

        if(res.data.providerId && res.data.locationId){
          api().get(`/member/app/market/provider/${this.providerId}?locationId=${this.locationId}`).then((res) => {
              if (res) {
                this.selectedProvider = res.data;
                this.loader = false;

                // Show the langauge
                setTimeout(() => {
                  console.log(document.getElementById('default_top_scroll'));
                  document.getElementById('default_top_scroll').scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "center",
                  });
                }, 500);
              }
          }); 
        }
        else {
          console.log("No Selected Provider");
          this.loader = false;
        }
      }
      })
      .catch((err) => {
        this.loader = false;
        console.log("err", err);
        alert(err.response.data);
      });
    },
  }
};
</script>

<style scoped lang="scss">

@media only screen and (max-width: 600px) {
  .register-page-container {
    width: 100%;
  }
}
</style>

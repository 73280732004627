var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header_marketpalce_container"},[_c('div',{staticClass:"header_marketplace_title"},[_vm._v(" Welcome to Advanced Primary Care - Powered by Apaly ")]),_c('div',{staticClass:"header_marketplace_sub"},[_vm._v(" Meet your personal provider today ")]),_c('div',{staticClass:"header_marketpalce_inputs"},[_c('div',{staticClass:"header_marketplace_search_cont"},[_c('img',{attrs:{"src":"/svg/icon_map_check.svg"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"header_marketplace_search",attrs:{"type":"text","id":"pac-input","placeholder":"Search by address"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing)return;_vm.address=$event.target.value}}})]),_c('button',{staticClass:"header_icon_btn",on:{"click":_vm.searchAddress}},[_c('img',{attrs:{"src":"/svg/icon_search.svg"}})])])]),_c('div',{staticClass:"welcome_contents"},[_c('div',{staticClass:"welcome_section"},[_c('div',{staticClass:"welcome_title"},[_vm._v("Search, compare and select your provider")]),_c('div',{staticClass:"welcome_sub"},[_vm._v("Choose from 1200+ clinics and 1000+ providers nationwide")]),_c('div',{staticClass:"welcome_map_cont",on:{"click":_vm.searchAddress}},[_c('GmapMap',{ref:"gmap",staticClass:"welcome_map",staticStyle:{"width":"100%"},attrs:{"center":_vm.center,"zoom":12,"clickableIcons:":"","false":"","options":_vm.mapOptions}},[_c('GmapMarker',{attrs:{"position":_vm.userCenter}}),_vm._l((_vm.places),function(place,index){return _c('GmapMarker',{key:index,attrs:{"position":place.coordinates,"icon":{
                            url: place.newPatients ? '/dark-blue.png' : '/dark-gray.png', 
                            scaledSize: { width: 40, height: 60 }
                        }},on:{"click":function($event){return _vm.openCard(place)}}})})],2)],1)]),_vm._m(0),_c('div',{staticClass:"welcome_section welcome_CTAs"},[_c('div',{staticClass:"welcome_row"},[_c('div',{staticClass:"welcome_col"},[_c('button',{staticClass:"welcome_CTA cta_primary",on:{"click":function($event){return _vm.goToSignup()}}},[_vm._v("Get Started")])]),_c('div',{staticClass:"welcome_col"},[_c('button',{staticClass:"welcome_CTA cta_secondary",on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("Login")])])])]),_c('div',{staticClass:"welcome_footer"},[_c('div',{staticClass:"welcome_row"},[_vm._m(1),_c('div',{staticClass:"welcome_col"},[_c('div',{staticClass:"welcome_foot_div"},[_c('div',{staticClass:"welcome_foot_title"},[_vm._v("Contact Us")]),_c('div',{staticClass:"welcoem_foot_small"},[_vm._v("813-938-6626 | info@apaly.com")]),_vm._v(" "),_c('br'),_c('div',{staticClass:"welcome_foot_icons"},[_c('img',{attrs:{"src":"/svg/icon_soc_fb.svg"},on:{"click":function($event){return _vm.openSoc('fb')}}}),_c('img',{attrs:{"src":"/svg/icon_soc_in.svg"},on:{"click":function($event){return _vm.openSoc('in')}}})]),_c('br'),_vm._m(2),_vm._v(" "),_c('br'),_c('br'),_vm._m(3),_vm._v(" "),_c('br'),_c('br')])])]),_c('div',{staticClass:"welcome_row"},[_c('div',{staticClass:"welcome_col"},[_c('div',{staticClass:"welcome_foot_last"},[_c('div',{staticClass:"welcome_copyright"},[_vm._v("COPYRIGHT © "+_vm._s(_vm.currentYear)+" APALY HEALTH INC.")]),_vm._m(4),_vm._m(5),_vm._m(6)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcome_section margin_b"},[_c('div',{staticClass:"welcome_title"},[_vm._v("Get started with 3 easy steps")]),_vm._v(" "),_c('br'),_c('br'),_c('div',{staticClass:"welcome_row"},[_c('div',{staticClass:"welcome_col display_flex display_flex_center"},[_c('div',{staticClass:"welcome_step_cont"},[_c('img',{staticClass:"welcome_step_img",attrs:{"src":"/illus_user.svg"}}),_c('div',{staticClass:"welcome_step_bg"},[_c('div',{staticClass:"welcome_step_text"},[_vm._v(" Have your insurance card and dependent information handy ")])])])]),_c('div',{staticClass:"welcome_col display_flex display_flex_center"},[_c('div',{staticClass:"welcome_step_cont"},[_c('img',{staticClass:"welcome_step_img",attrs:{"src":"/illus_location.svg"}}),_c('div',{staticClass:"welcome_step_bg extra_bg"},[_c('div',{staticClass:"welcome_step_text"},[_vm._v(" Find a provider near you and create your account ")])])])]),_c('div',{staticClass:"welcome_col display_flex display_flex_center"},[_c('div',{staticClass:"welcome_step_cont"},[_c('img',{staticClass:"welcome_step_img",attrs:{"src":"/illus_doctors.svg"}}),_c('div',{staticClass:"welcome_step_bg"},[_c('div',{staticClass:"welcome_step_text"},[_vm._v(" Meet your provider virtually or in person to activate your membership ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcome_col"},[_c('div',{staticClass:"welcome_foot_div"},[_c('img',{staticClass:"welcome_logo",attrs:{"src":"/apalywhite.svg"}}),_c('div',{staticClass:"welcome_foot_title"},[_vm._v("Advanced Primary Care")]),_vm._v(" "),_c('br'),_c('div',{staticClass:"welcome_foot_title"},[_vm._v("”It's like having a doctor in your family”")]),_c('div',{staticClass:"welcoem_foot_small"},[_c('br'),_vm._v(" Apaly is a software platform and B2B marketplace that connects employers, TPAs, and carriers with point solution vendors and providers to streamline the business of healthcare. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcoem_foot_small"},[_c('strong',[_vm._v("Disclaimer:")]),_vm._v(" Please note that access to Apaly Health is exclusive to employees whose employers have partnered with Advanced Primary Care providers. If your employer is not yet offering this benefit, please contact us at "),_c('span',[_vm._v("info@apaly.com")]),_vm._v(", and our team will reach out to your employer about offering APC. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcoem_foot_small"},[_vm._v(" Looking to partner with Apaly? To learn more about how we work with Healthcare Providers, TPAs, and employers visit our "),_c('a',{attrs:{"href":"https://apaly.com/"}},[_vm._v("website")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcom_links"},[_c('a',{attrs:{"href":"https://apaly.app/apaly-privacy-policy/"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcom_links"},[_c('a',{attrs:{"href":"https://apaly.app/apaly-cookie-policy/"}},[_vm._v("Cookie Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcom_links"},[_c('a',{attrs:{"href":"https://apaly.app/apaly-health-inc-term-and-conditions/"}},[_vm._v("Terms of Use")])])
}]

export { render, staticRenderFns }
<template>
    <div class="no-auth-header header_cont" :style="`${loggedIn ? 'display:none;':''}`">
      <!-- Logo -->
      <div  class="header_logo_cont"> 
        <span class="header_logo"><img src="/apalywhite.svg"> </span>
      </div>

    <div class="">
      <i class="show-on-mobile fas fa-bars  pt-1 ml-2" style="cursor: pointer; color: white" @click="showNavigationDrawer()" ></i>
      <div class=" hide-on-mobile no-auth-header--right-side-buttons d-flex">
        <button @click="goToSignup()" class="header_getStarted_btn">Get Started</button>
        <button @click="$router.push('/login')" class="header_login_btn">Login</button>
      </div>
    </div> 
     <v-navigation-drawer
     class="my-drawer"
      v-model="drawer"
      temporary
      top
      touchless
      app
      style="position: absolute; top: 0; height: 120vh;"
    >
      <v-list density="compact" nav>
         <v-list-item>
          <img src="/apaly-logo.png" width="80px" height="40px" />
          <!-- <v-icon @click="drawer = !drawer" class="ml-auto">mdi-close</v-icon> -->
        </v-list-item>
        <v-divider horizontal> </v-divider>
        <v-list-item class="mt-5">
          <!-- <v-icon color="#0069F3">mdi-login</v-icon> -->
          <v-icon color="#0069F3">mdi-chevron-right</v-icon>
          <span class="ml-2 font-16 font-weight-400" style="color: #3569B2" @click="$router.push('/marketplace')">
            Get Started
          </span>
        </v-list-item>

        <v-list-item>
          <v-icon color="#0069F3">mdi-login</v-icon>
          <span class="ml-2 font-16 font-weight-400" style="color: #3569B2" @click="$router.push('/login')">
            Login
          </span>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
  </div>

</template>
<script>
export default {
  data() {
    return {
      drawer: null,
      menu: false,
      showComponents: false,
      profileImg: "",
      user: {},

      items: [
        {
          text: "Sign Out",
          link: "/login",
        },
      ],
    };
  },
  components:{
  },
  computed: {
    loggedIn() {
      if(this.$cookies.get("token")) {
        return true
      }
      else {
        return false
      }
    },
  },
  watch: {
    // getComponentState is not being used, so just comment out the watch code

    // getComponentState() {
    //   this.showComponents = localStorage.getItem("user") && this.$cookies.get("token");
    // },
  },
  created() {
    // I don't know when but localStorage.user isn't being used in here anymmore
    // So I'll just comment these out
    // let userObject = localStorage.getItem("user");
    // this.user =  JSON.parse(userObject)
    
    // if ( localStorage.getItem("user") && this.$cookies.get("token")) {
    //    this.showComponents = true;
    //   }
    //   else{
    //     this.showComponents = false;
    //   }
    
  },
  methods: {
    goToSignup() {
        this.$router.push("/register").catch(err => {
            if (err.name !== 'NavigationDuplicated') {
            throw err;
            }
        });
    },
    goToMarketplace() {
      this.$router.push("/marketplace").catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    goToRoute(link) {
      if (link.includes("login")) {
        this.logout();
      } else {
        this.$route.path != link ? this.$router.push(link) : "";
      }
    },
    showNavigationDrawer() {
      this.drawer = !this.drawer;
    },
    logout(item) {
      localStorage.clear();
      this.$cookies.remove("token");
      this.$store.commit("setComponentState", false);
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped lang="scss">
.no-auth-header {
  // background-color: white;
  position: fixed;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  &--left-side {
    display: flex;
    align-items: center;
    &-logo {
      padding: 0 50px;
      border-right: 1px solid #ccc;
      img {
        width: 100px;
      }
    }
    &-name {
      font-size: 20px;
      span {
        padding: 0;
      }
    }
  }
  &--right-side {
    &-buttons {
      button {
        margin-left: 10px;
        border-radius: 10px;
      }
    }
  }
}
.v-overlay__scrim{
    opacity: 1 !important; /* Set opacity to 1 to make it fully opaque */
    background-color: white !important; /* Set background color to transparent */
    border-color: transparent !important; /* Set border color to transparent if needed */
  }
.show-on-mobile {
  display: none;
}
.v-navigation-drawer__scrim  {
    opacity: 1 !important; 
    background-color: none !important;
    border-color: none !important; 
  }
@media only screen and (max-width: 600px) {
  .show-on-mobile {
    display: flex;
  }
  .no-auth-header {
    border-bottom:  none !important;
    // border: 1px solid red !important;
    height: 75px !important;
    background-color: #102A4C;
    position: fixed;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    // padding-bottom: 0px;
    align-items: center;
    &--left-side {
      display: flex;
      align-items: center;
      &-logo {
        padding: 0 30px;
        border-right: 1px solid #ccc;
        img {
          width: 80px;
        }
      }
      &-name {
        font-size: 13px;
        span {
          padding: 0 10px 0 30px;
        }
      }
    }
    &--right-side {
      &-buttons {
        button {
          margin-left: 10px;
          border-radius: 10px;
        }
      }
    }
  }
  .hide-on-mobile {
    display: none !important;
  }
  .v-overlay__scrim{
    opacity: 1 !important; /* Set opacity to 1 to make it fully opaque */
    background-color: white !important; /* Set background color to transparent */
    border-color: transparent !important; /* Set border color to transparent if needed */
  }
}
</style>
